
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    FilterFormInterface,
    ResponseDataStats as responseStats,
    Stats,
    ChartError
} from "@/interfaces";
import { Eoption, noData } from "@/utils";

import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CardStats: () => import("@/components/layout/CardStats.vue"),
        CardProgress: () => import("@/components/layout/CardProgress.vue"),
        Echart: () => import("@/components/charts/eChart.vue"),
        TabItem: () => import("@/components/layout/TabItem.vue"),
        FilterForm: () =>
            import("@/pages/components/forms/FilterFormStandardV2.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Overview extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormInterface = {
        company: "",
        client: "",
        installation: "",
        modbus: "",
        valid: true,
        box: "",
        element: "",
        periodType: "Semanal",
        start: undefined,
        end: undefined,
        window: "1h",
        aggregation: "mean",
        comparative: false,
        days: ""
    };

    showAll = false;
    showFilter = true;
    tensionStats: Stats = {};
    tensionChart: any = noData;
    currentStats: Stats = {};
    currentInterruptor = 43.5;
    currentChart: any = noData;
    activePowerStats: Stats = {};
    activePowerChart: any = noData;
    powerFactorChart: any = noData;
    thdvStats: Stats = {};
    thdvChart: any = noData;
    thdiStats: Stats = {};
    thdiChart: any = noData;
    tensionStats2: Stats = {};
    tensionChart2: any = noData;
    currentStats2: Stats = {};
    currentInterruptor2 = 43.5;
    currentChart2: any = noData;
    activePowerStats2: Stats = {};
    activePowerChart2: any = noData;
    powerFactorChart2: any = noData;
    thdvStats2: Stats = {};
    thdvChart2: any = noData;
    thdiStats2: Stats = {};
    thdiChart2: any = noData;

    errors: ChartError[] = [];

    // mounted() {
    //     this.getBucketBoundDates();
    // }

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }

    /**
     * Methods
     */

    async fetchData() {
        this.setProgressBar(true);
        /**
         * Requests
         */

        this.tensionStats = {};
        this.tensionChart = noData;
        this.currentStats = {};
        this.currentInterruptor = 0;
        this.currentChart = noData;
        this.activePowerStats = {};
        this.activePowerChart = noData;
        this.powerFactorChart = noData;
        this.thdvStats = {};
        this.thdvChart = noData;
        this.thdiStats = {};
        this.thdiChart = noData;
        this.tensionStats2 = {};
        this.tensionChart2 = noData;
        this.currentStats2 = {};
        this.currentInterruptor2 = 0;
        this.currentChart2 = noData;
        this.activePowerStats2 = {};
        this.activePowerChart2 = noData;
        this.powerFactorChart2 = noData;
        this.thdvStats2 = {};
        this.thdvChart2 = noData;
        this.thdiStats2 = {};
        this.thdiChart2 = noData;
        let start = "";
        let end = "";
        const stop = this.filterForm.comparative ? [0, 1] : [0];

        for (const i of stop) {
            if (this.filterForm.start && this.filterForm.end) {
                if (!this.filterForm.comparative) {
                    start = this.filterForm.start;
                    end = this.filterForm.end;
                } else if (i == 0) {
                    start = this.filterForm.start;
                    end = new Date(
                        Number(new Date(this.filterForm.start)) +
                            Number(this.filterForm.days) * 86400000
                    )
                        .toISOString()
                        .split("T")[0];
                } else {
                    start = this.filterForm.end;
                    end = new Date(
                        Number(new Date(this.filterForm.end)) +
                            Number(this.filterForm.days) * 86400000
                    )
                        .toISOString()
                        .split("T")[0];
                }
            }

            await this.$api
                .getMeasuresElement<responseStats>(
                    this.filterForm.box,
                    this.filterForm.analyzer,
                    this.filterForm.element,
                    start,
                    end,
                    this.filterForm.window,
                    this.filterForm.aggregation,
                    this.filterForm.installation.split(" - ")[0]
                )
                .then(async response => {
                    await sleep(500);

                    if (response.ok) {
                        if (i == 0) {
                            this.activePowerStats =
                                response.data["measures"]["potencia"]["stat"];
                            this.activePowerChart =
                                response.data["measures"]["potencia"]["fig"];
                            try {
                                this.currentStats =
                                    response.data["measures"]["corriente"][
                                        "stat"
                                    ];
                                this.currentChart =
                                    response.data["measures"]["corriente"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.tensionStats =
                                    response.data["measures"]["tension"][
                                        "stat"
                                    ];
                                this.tensionChart =
                                    response.data["measures"]["tension"]["fig"];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.powerFactorChart =
                                    response.data["measures"]["factorPotencia"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.thdvStats =
                                    response.data["measures"]["tensionTHD"][
                                        "stat"
                                    ];
                                this.thdvChart =
                                    response.data["measures"]["tensionTHD"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.thdiStats =
                                    response.data["measures"]["corrienteTHD"][
                                        "stat"
                                    ];
                                this.thdiChart =
                                    response.data["measures"]["corrienteTHD"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                        } else {
                            try {
                                this.activePowerStats2 =
                                    response.data["measures"]["potencia"][
                                        "stat"
                                    ];
                                this.activePowerChart2 =
                                    response.data["measures"]["potencia"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.currentStats2 =
                                    response.data["measures"]["corriente"][
                                        "stat"
                                    ];
                                this.currentChart2 =
                                    response.data["measures"]["corriente"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.tensionStats2 =
                                    response.data["measures"]["tension"][
                                        "stat"
                                    ];
                                this.tensionChart2 =
                                    response.data["measures"]["tension"]["fig"];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.powerFactorChart2 =
                                    response.data["measures"]["factorPotencia"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.thdvStats2 =
                                    response.data["measures"]["tensionTHD"][
                                        "stat"
                                    ];
                                this.thdvChart2 =
                                    response.data["measures"]["tensionTHD"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                            try {
                                this.thdiStats2 =
                                    response.data["measures"]["corrienteTHD"][
                                        "stat"
                                    ];
                                this.thdiChart2 =
                                    response.data["measures"]["corrienteTHD"][
                                        "fig"
                                    ];
                            } catch {
                                error => {
                                    if (error instanceof Error) {
                                        this.showError(error.message);
                                    }
                                };
                            }
                        }
                    } else {
                        this.showError(response["message"]);
                    }
                })
                .catch(error => {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                });
        }
        this.showAll = true;
        this.setProgressBar(false);
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    formatDates() {
        if (this.filterForm?.start && this.filterForm.end)
            return (
                new Date(this.filterForm?.start).toDateString() +
                " - " +
                new Date(this.filterForm.end).toDateString()
            );
        return "";
    }

    checkChartErrors(chart: string) {
        const error = this.errors.filter(error => error.chart === chart);
        if (error.length && error[0].chart === chart) {
            this.showError(error[0].message);
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    getEchart(option) {
        if (option == 0) {
            return 0;
        }
        if (option.dataset) {
            if (option.dataset.source) {
                if (option.dataset.source.length > 0) {
                    return { ...Eoption, ...option };
                }
            }
        }
        return noData;
    }
}
